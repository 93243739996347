// import { Box, Button, TextField } from "@mui/material";
// import { Formik } from "formik";
// import * as yup from "yup";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import Header from "../../components/Header";
// import { useState } from "react";
// import ReactQuill from "react-quill";
// import { useQuill } from "react-quilljs";
// import "react-quill/dist/quill.snow.css";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

// const CreatePost = () => {
//   const { quill, quillRef } = useQuill(); // Hook to initialize Quill
//   const isNonMobile = useMediaQuery("(min-width:600px)");
//   const [value, setValue] = useState("");
//   const [titleValue, setTitleValue] = useState("");
//   const navigate = useNavigate();
//   const notify = (x) => toast(x);
//   const notifyError = (x) => toast.error(x);

//   useEffect(() => {
//     if (quill) {
//       // Listen to text-change events
//       quill.on("text-change", (delta, oldDelta, source) => {
//         console.log("Text change!");
//         console.log(quill.getText()); // Plain text
//         console.log(quill.getContents()); // Quill Delta
//       });
//     }
//   }, [quill]);

//   // Custom Toolbar Configuration
//   const modules = {
//     toolbar: [
//       [{ header: [1, 2, 3, 4, 5, 6, false] }], // Header dropdown
//       [{ font: [] }], // Font family
//       [{ size: ["small", false, "large", "huge"] }], // Font size
//       ["bold", "italic", "underline", "strike"], // Text styles
//       [{ color: [] }, { background: [] }], // Font and background colors
//       [{ script: "sub" }, { script: "super" }], // Subscript/Superscript
//       [{ align: [] }], // Text alignment
//       ["blockquote", "code-block"], // Blockquote and code block
//       [{ list: "ordered" }, { list: "bullet" }], // Lists
//       ["link", "image", "video"], // Insert link, image, video
//       ["clean"], // Remove formatting
//     ],
//   };

//   const handleFormSubmit = async (event) => {
//     console.log(titleValue);
//     console.log(value);
//     event.preventDefault();

//     let formData = { title: titleValue, body: value };
//     let tbody = JSON.stringify(formData);
//     console.log("head: ", tbody.title);
//     console.log("body: ", tbody.body);
//     console.log("exodia: ", tbody);

//     try {
//       const response = await fetch(
//         "https://us-central1-argon-fx-383518.cloudfunctions.net/writeToDatabase", // Replace with your Cloud Function URL
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(formData),
//         }
//       );
//       console.log("inner: ", formData);
//       console.log("inner strngfy: ", JSON.stringify(formData));

//       if (response.ok) {
//         // Form submitted successfully
//         notify("Form submitted successfully!");

//         navigate("/news");
//       } else {
//         // Error submitting form
//         console.error("Error submitting form");
//       }
//     } catch (error) {
//       notifyError("Error: " + error.message + " [SE-NEWS]");
//       console.error(error);
//     }
//   };

//   return (
//     <Box m="20px">
//       <Header title="Create post" subtitle="Create a new post" />
//       <Box m="50px" minHeight={"500px"}>
//         <TextField
//           fullWidth
//           variant="filled"
//           type="text"
//           label="Title"
//           name="title"
//           onChange={(e) => setTitleValue(e.target.value)}
//           value={titleValue.value}
//           sx={{ gridColumn: "span 2" }}
//         />
//         {/* <ReactQuill
//           theme="snow"
//           value={value}
//           onChange={setValue}
//           modules={modules}
//           placeholder="Write your post content here..."
//         /> */}
//         <div style={{ height: 300 }} ref={quillRef} />
//         <Box m="20px" display={"flex"} justifyContent={"end"}>
//           <Button
//             onClick={handleFormSubmit}
//             color="secondary"
//             variant="contained"
//           >
//             Create New Post
//           </Button>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// const phoneRegExp =
//   /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

// const checkoutSchema = yup.object().shape({
//   firstName: yup.string().required("required"),
//   lastName: yup.string().required("required"),
//   email: yup.string().email("invalid email").required("required"),
//   contact: yup
//     .string()
//     .matches(phoneRegExp, "Phone number is not valid")
//     .required("required"),
//   address1: yup.string().required("required"),
//   address2: yup.string().required("required"),
// });
// const initialValues = {
//   firstName: "",
//   lastName: "",
//   email: "",
//   contact: "",
//   address1: "",
//   address2: "",
// };

// export default CreatePost;

import { Box, Button, TextField } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useQuill } from "react-quilljs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "quill/dist/quill.snow.css";

const CreatePost = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [value, setValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const navigate = useNavigate();
  const notify = (x) => toast(x);
  const notifyError = (x) => toast.error(x);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const { quill, quillRef } = useQuill({ modules });

  useEffect(() => {
    if (quill) {
      // Listen to text-change events
      quill.on("text-change", () => {
        setValue(quill.root.innerHTML); // Update value with editor content (HTML format)
      });
    }
  }, [quill]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!quill) {
      console.error("Quill instance is not initialized.");
      return;
    }

    const editorContent = quill.getContents(); // Delta format
    const editorHTML = quill.root.innerHTML; // HTML format

    const formData = {
      title: titleValue,
      body: JSON.stringify(editorContent), // Save Delta format as a string
    };

    console.log('formData: ', formData)

    try {
      const response = await fetch(
        "https://us-central1-argon-fx-383518.cloudfunctions.net/writeToDatabase", // Replace with your Cloud Function URL
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        notify("Post created successfully!");
        navigate("/news");
      } else {
        const errorText = await response.text();
        console.error("Error submitting form:", errorText);
        notifyError(`Error: ${errorText}`);
      }
    } catch (error) {
      console.error("Error during submission:", error);
      notifyError("Error: " + error.message);
    }
  };

  return (
    <Box m="20px">
      <Header title="Create Post" subtitle="Create a new post" />
      <Box m="50px" minHeight={"500px"}>
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Title"
          name="title"
          onChange={(e) => setTitleValue(e.target.value)}
          value={titleValue}
          sx={{ gridColumn: "span 2" }}
        />
        <div style={{ height: 300 }} ref={quillRef} />
        <Box m="20px" display={"flex"} justifyContent={"end"}>
          <Button
            onClick={handleFormSubmit}
            color="secondary"
            variant="contained"
          >
            Create New Post
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreatePost;
