import React from "react";
import { Box, Button, useTheme } from "@mui/material";
import Header from "../components/Header";
import Typography from "@mui/material/Typography";
import { tokens } from "../theme";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import { Link } from "react-router-dom";

const OnlineMarketing = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      className="m-5"
      display="grid"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
    >
      <Header title="Online Marketing" sx={{ gridColumn: "span 2" }} />
      <Typography
        color={colors.greenAccent[500]}
        variant="p"
        sx={{ gridColumn: "span 4" }}
        width={"500px"}
      >
        Authorized Retail Partners and any sub-affiliates must fully implement
        the 115 negative keywords previously provided to ensure none of the ads
        show up for broad-match or exact match to the corresponding keywords in
        Google, Yahoo, or Bing campaigns.
      </Typography>
      <Button
        sx={{
          backgroundColor: colors.blueAccent[700],
          gridColumn: "span 4",
          color: colors.grey[100],
          fontSize: "12px",
          fontWeight: "bold",
          padding: "10px 10px",
          marginTop: "30px",
          maxWidth: "200px",
        }}
        className="m-4"
        component={Link}
        to="/adApproval"
      >
        <FacebookIcon sx={{ mr: "10px" }} />
        <GoogleIcon sx={{ mr: "10px" }} />
        Google Facebook Ad Approval
      </Button>

      <Box sx={{ gridColumn: "span 4" }} className="my-5">
        <p className="m-1">
          <a
            href={
              "https://docs.google.com/spreadsheets/d/17Zpsit1pdHkHHSu-h5-ZwJ4S80e31g8DcCY4alTvALs/edit?usp=sharing"
            }
            target="_blank"
          >
            Spectrum Negative Keywords
          </a>
        </p>
        <p className="m-1">
          <a
            href={
              "https://docs.google.com/spreadsheets/d/1MQc9TNen6SMEDePftuaE2bZwS6EGDvLDGjQLh5fY5iE/edit?usp=sharing"
            }
            target="_blank"
          >
            Spectrum Zip Code List
          </a>
        </p>
        <p className="m-1">
          <a
            href={'https://46bd7449-5a06-4efe-9cab-993f0bf996fb.usrfiles.com/archives/46bd74_8e38f731681d4ffebd7d0cfdb85f1ac9.zip'
            }
            target="_blank"
          >
            Spectrum Authorized Logo
          </a>
        </p>
        <p className="m-1">
          <a
            href={
              "https://docs.google.com/presentation/d/1qRLDCVJhkaujrU-xUzwtA0VWrXMY5NsfRJnr6WyaPUI/edit?usp=sharing"
            }
            target="_blank"
          >
            Spectrum Marketing Guidelines
          </a>
        </p>
        <p className="m-1">
          <a
            href={
              "https://docs.google.com/spreadsheets/d/1QKBb-n38j2bmrgx_tJRqeGg2obadhAafq2GJ4y6bVmU/edit?usp=sharing"
            }
            target="_blank"
          >
            Spectrum Offer Disclaimers
          </a>
        </p>
        <p className="m-1">
          <a
            href={
              "https://docs.google.com/presentation/d/1vd9tlHz_w-nfBrLS7ig8cU4ViVEMQnp5OoMJ1J-FzJw/edit?usp=sharing"
            }
            target="_blank"
          >
            Spectrum Website Guide
          </a>
        </p>
        <p className="m-1">
          <a
            href={"https://46bd7449-5a06-4efe-9cab-993f0bf996fb.usrfiles.com/archives/46bd74-2cf1971d9de541149c43543ef7fa635e.zip"}
            target="_blank"
          >
            Spectrum Fonts
          </a>
        </p>
        <p className="m-1">
          <a
            href={"https://46bd7449-5a06-4efe-9cab-993f0bf996fb.usrfiles.com/archives/46bd74-245a5471e6cd4d30b5ebf031350bfe55.zip"}
            target="_blank"
          >
            Spectrum Website Template
          </a>
        </p>
      </Box>
    </Box>
  );
};

export default OnlineMarketing;
