import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Box, Button, Typography, useTheme, Modal } from "@mui/material";
import { tokens } from "../../theme";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Iframe from "react-iframe";
import { toast } from "react-toastify";

const ServiceProviders = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [providers, setProviders] = useState({});
  const [expanded, setExpanded] = useState("internet");
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const notify = (x) => toast(x);
  const notifyError = (x) => toast.error(x);
  const { zipCode, URL, values } = location.state || {};
  const fullAccessPartners = ['VTL1000','VTL1001','VTL1002', 'VTL1003']
  let Values;
  let AgentID;
  let Agent;
  let Url;
  Values = location.state.values;
  const CustID = location.state.values.CustID;
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (props.agent) {
    AgentID = props.agent.AgentID;
    Agent = props.agent;
  }

  useEffect(() => {
    fetchServiceProviders(zipCode);
  }, [zipCode]);

  // Handlers for modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (location.state) {
    console.log("L O U D : ", location.state);

    Values = location.state.values;
    if (Values.campaign != "") {
      // if (true) {
      Url = `https://fs10.formsite.com/yU3au7/9lz5bu81xy/fill?id0=${AgentID}&id173=${CustID}&id365=${
        values.adType == "Messenger"
          ? 1
          : values.adType == "Incoming Call"
          ? 2
          : values.adType == "Form"
          ? 3
          : values.adType == "Followup"
          ? 4
          : null
      }&id367=${values.campaign}&id89=${null}&id6=${values.address1}&id7=${
        values.address2
      }&id10=${values.zipCode}&id94=${values.moving ? 1 : 2}&id95=${
        values.prevaddress1
      }&id96=${values.prevApt}&id97=${values.prevZip}&id4=${
        values.firstName
      }&id5=${values.lastName}&id20=${null}&id98=${values.mobile}&id22=${
        values.email
      }`;
    } else {
      Url = `https://fs10.formsite.com/yU3au7/9v1qdwlvqg/fill?id0=${AgentID}&id89=null&id6=${
        Values.address1
      }&id7=${Values.address2}&id10=${Values.zipCode}&id94=${
        values.moving ? 1 : 2
      }&id95=${Values.prevaddress1}&id96=${Values.prevApt}&id97=${
        Values.prevZip
      }&id4=${Values.firstName}&id5=${Values.lastName}&id20=null&id98=${
        Values.mobile
      }&id22=${Values.email}`;
      // Url = `https://fs10.formsite.com/yU3au7/9v1qdwlvqg/fill?id0=${AgentID}&id6=${Values.address1}&id7=${Values.address2}&id10=${Values.zipCode}&id94=${Values.moving}&id95=${Values.prevaddress1}&id96=${Values.prevaddress2}&id97=${Values.zipCode}&id4=${Values.firstName}&id5=${Values.lastName}&id98=${Values.mobile}&id22=${Values.email}`;
    }
  } else {
    Url = `https://fs10.formsite.com/yU3au7/9v1qdwlvqg/fill?id0=${AgentID}`;
  }

  const fetchServiceProviders = async () => {
    let Payload = { ZipCode: zipCode };
    try {
      const response = await fetch(
        `https://us-central1-argon-fx-383518.cloudfunctions.net/ZipServiceProviders`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Payload),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Service Providers: ", data);
      setProviders(data[0] || {});
    } catch (error) {
      console.error("Error fetching service providers:", error);
    }
  };

  const handleADT = async () => {
    // let Payload = { ZipCode: zipCode };
    handleClose();
    try {
      const response = await fetch(
        `https://us-central1-argon-fx-383518.cloudfunctions.net/ADTOrderWrite`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Values),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
        notifyError("ADT referral Failed!");
      } else {
        notify("ADT referral Successful!");
      }
      console.log("ADT: good");
    } catch (error) {
      notifyError("ADT referral Failed!");
      console.error("Error fetching service providers:", error);
    }
  };

  // const handleProviders = (provider) => {
  //   console.log(provider);
  //   switch (provider) {
  //     case "Spectrum":
  //       window.open(URL, "_blank", "noopener,noreferrer");
  //       break;
  //     case "ADT":
  //       handleOpen();
  //       break;
  //     default:
  //       console.log("Provider not handled:", provider);
  //   }
  // };

  const handleProviders = (provider) => {
    console.log(provider);
    switch (provider) {
      case "Spectrum":
        // Retail Portal
        window.open(URL, "_blank", "noopener,noreferrer");
        break;
      case "Spectrum_B":
        // Retail Portal
        window.open(
          "https://cpartner.spectrum.com/address/agent-smbcpartner/",
          "_blank",
          "noopener,noreferrer"
        );
        break;

      case "Frontier":
        // Retail Portal
        window.open(
          "https://partnerportal2.frontier.com/login",
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "Cox":
      case "Xfinity_U":
      case "Brightspeed":
      case "Centurylink":
      case "WoW":
        // Updater Portal
        window.open(
          `http://partnerscc.digitalcommercenow.com/?agentname=${Agent.FirstName}&agentcode=${AgentID}&promoid=5002424&locationcode=MyConnect&secondarycallcode=%P`,
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "Optimum":
      case "Kinetic":
      case "Earthlink":
      case "DirectTV":
      case "Viasat":
        // Chuzzo Portal
        window.open(
          "https://perfectvisionpoe.my.site.com/poe/login?ec=302&startURL=%2Fpoe%2Fs%2F",
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "Xfinity_J":
      case "Buckeye":
      case "Hawaiian":
      case "Hughesnet":
      case "MaxxSouth":
      case "Mediacom":
      case "Metronet":
      case "RiseBB":
      case "Smithville":
      case "TDS":
      case "Ziply":
      case "Lumos":
      case "Clearwave":
      case "Breezeline":
      case "Altafiber":
      case "VerizonFios":
        // Partners Portal
        window.open(
          `https://partnerchannel.org/`,
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "AT&T":
        // Sara+ Portal
        window.open("#", "_blank", "noopener,noreferrer");
        break;
      case "ADT":
        // Referral
        handleOpen();
        break;
      default:
        console.log("Provider not handled:", provider);
    }
  };

  const handleScroll = () => {
    const element = document.getElementById("formFrame");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isProvidersEmpty =
    Object.keys(providers).length === 0 || !providers.ZipCode;

  return (
    <Box class="m-5">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Header title="Select Provider" />
        </Box>

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[500],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              mb: "30px",
            }}
            onClick={handleScroll}
          >
            REGISTER SALE
          </Button>
        </Box>
      </Box>
      <Box
        mt={"-10px"}
        ml={"20px"}
        display="grid"
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
      >
        <Typography color={colors.primary[800]} variant="h5" fontWeight="800">
          Customer ID: {CustID}
        </Typography>
        <Typography color={colors.primary[600]} variant="p" fontWeight="800">
          {`Name: ${values.firstName} ${values.lastName} - Address: ${values.address1} - Zip: ${values.zipCode} - Mobile: ${values.mobile} - Email: ${values.email}`}
        </Typography>
      </Box>
      <Box className="m-5">
        {isProvidersEmpty ? (
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Typography
              color={colors.greenAccent[500]}
              variant="h3"
              fontWeight="bold"
            >
              Loading..
            </Typography>
          </Box>
        ) : (
          <>
            <Accordion
              expanded={expanded === "internet"}
              onChange={handleChange("internet")}
              backgroundColor="#459ad9"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="internet-content"
                id="internet-header"
                color={colors.lightHeaders[100]}
                sx={{
                  backgroundColor: "#459ad9",
                }}
              >
                <Typography color={colors.lightHeaders[100]} variant="h3">
                  Internet
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(6, minmax(0, auto))"
                  columnGap="20px"
                  rowGap="20px"
                  justifyContent="start"
                >
                  {[
                    "Spectrum",
                    "Cox",
                    "Optimum",
                    "Frontier",
                    "Xfinity_J",
                    "Xfinity_U",
                    "Kinetic",
                    "Earthlink",
                    "Brightspeed",
                    "Buckeye",
                    "Centurylink",
                    "Hawaiian",
                    "Hughesnet",
                    "Maxxsouth",
                    "Mediacom",
                    "Metronet",
                    "RiseBB",
                    "Smithville",
                    "TDS",
                    "WoW",
                    "Ziply",
                    "Viasat",
                    "Lumos",
                    "Clearwave",
                    "Breezeline",
                    "Smithville",
                    "Altafiber",
                    "VerizonFios",
                  ].map((provider) => {
                    if (providers[provider] === "Yes") {
                      const isAgentAuthorized = fullAccessPartners.includes(Agent?.PartnerID);
                      console.log('isagentauth: ', isAgentAuthorized)
                      return (
                        <Box
                          key={provider}
                          sx={{
                            gridColumn: "span 1",
                            height: "auto",
                            width: "200px",
                            cursor:
                              isAgentAuthorized || provider == "Spectrum"
                                ? "pointer"
                                : "not-allowed",
                          }}
                          onClick={
                            isAgentAuthorized || provider == "Spectrum"
                              ? () => handleProviders(provider)
                              : null
                          }
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `assets/Logos/${provider} Button${
                                isAgentAuthorized || provider == "Spectrum"
                                  ? ""
                                  : " G"
                              }.png`
                            }
                            alt={`${provider} Logo`}
                          />
                        </Box>
                      );
                    }
                    return null;
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "business internet"}
              onChange={handleChange("business internet")}
              backgroundColor="#459ad9"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="business-internet-content"
                id="business-internet-header"
                color={colors.lightHeaders[100]}
                sx={{
                  backgroundColor: "#459ad9",
                }}
              >
                <Typography color={colors.lightHeaders[100]} variant="h3">
                  Business Internet
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(6, minmax(0, auto))"
                  columnGap="20px"
                  rowGap="20px"
                  justifyContent="start"
                >
                  {["Spectrum_B"].map((provider) => {
                    if (providers[provider] === "Yes") {
                      const isAgentAuthorized = fullAccessPartners.includes(Agent?.PartnerID);
                      return (
                        <Box
                          key={provider}
                          sx={{
                            gridColumn: "span 1",
                            height: "auto",
                            width: "200px",
                            cursor: isAgentAuthorized
                              ? "pointer"
                              : "not-allowed",
                          }}
                          onClick={
                            isAgentAuthorized
                              ? () => handleProviders(provider)
                              : null
                          }
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `assets/Logos/${provider} Button${
                                isAgentAuthorized ? "" : " G"
                              }.png`
                            }
                            alt={`${provider} Logo`}
                          />
                        </Box>
                      );
                    }
                    return null;
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "television"}
              onChange={handleChange("television")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="television-content"
                id="television-header"
                sx={{
                  backgroundColor: "#459ad9",
                }}
              >
                <Typography color={colors.lightHeaders[100]} variant="h3">
                  Television
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(6, minmax(0, auto))"
                  columnGap="20px"
                  rowGap="20px"
                  justifyContent="start"
                >
                  {[
                    "DirectTV",
                    "Spectrum",
                    "Optimum",
                    "Xfinity_U",
                    "Xfinity_J",
                    "Hawaiian",
                  ].map((provider) => {
                    if (providers[provider] === "Yes") {
                      const isAgentAuthorized = fullAccessPartners.includes(Agent?.PartnerID);
                      return (
                        <Box
                          key={provider}
                          sx={{
                            gridColumn: "span 1",
                            height: "auto",
                            width: "200px",
                            cursor:
                              isAgentAuthorized || provider === "Spectrum"
                                ? "pointer"
                                : "not-allowed",
                          }}
                          onClick={
                            isAgentAuthorized || provider === "Spectrum"
                              ? () => handleProviders(provider)
                              : null
                          }
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `assets/Logos/${provider} Button${
                                isAgentAuthorized || provider === "Spectrum"
                                  ? ""
                                  : " G"
                              }.png`
                            }
                            alt={`${provider} Logo`}
                          />
                        </Box>
                      );
                    }
                    return null;
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "security"}
              onChange={handleChange("security")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="television-content"
                id="security-header"
                sx={{
                  backgroundColor: "#459ad9",
                }}
              >
                <Typography color={colors.lightHeaders[100]} variant="h3">
                  Security
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(4, minmax(0, auto))"
                  columnGap="20px"
                  rowGap="20px"
                  justifyContent="start"
                >
                  {["ADT"].map(
                    (provider) =>
                      providers[provider] === "Yes" && (
                        <Box
                          onClick={() => handleProviders(provider)}
                          key={provider}
                          sx={{
                            gridColumn: "span 1",
                            height: "auto",
                            width: "200px",
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `assets/Logos/${provider} Button${
                                provider === "ADT" ? "" : " G"
                              }.png`
                            }
                          />
                        </Box>
                      )
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Box>
      <div>
        <Iframe
          url={Url}
          width="100%"
          height="850px"
          id="formFrame"
          className="formFrame"
          display="initial"
          position="relative"
        />
      </div>
      {/* Clock-In Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="ADT-modal-title"
        aria-describedby="ADT-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="ADT-modal-title" variant="h3" component="h2">
            ADT
          </Typography>
          <Typography id="ADT-modal-description" sx={{ mt: 2 }}>
            Refer {Values.firstName} {Values.lastName} to an ADT Specialist?
          </Typography>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button variant="contained" onClick={handleClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleADT}
              sx={{ color: "white", bgcolor: colors.primary[600] }}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ServiceProviders;

// import React, { useEffect, useState } from "react";
// import Header from "../../components/Header";
// import { Box, Button, Typography, useTheme } from "@mui/material";
// import { tokens } from "../../theme";
// import { useLocation, useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";

// const ServiceProviders = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const [providers, setProviders] = useState({});

//   const location = useLocation();
//   const navigate = useNavigate();
//   const { zipCode, URL, values } = location.state || {};

//   useEffect(() => {
//     fetchServiceProviders(zipCode);
//   }, [zipCode]);

//   const fetchServiceProviders = async (zipCode) => {
//     let Payload = { ZipCode: zipCode };
//     try {
//       const response = await fetch(
//         `https://us-central1-argon-fx-383518.cloudfunctions.net/ZipServiceProviders`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(Payload),
//         }
//       );
//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }
//       const data = await response.json();
//       console.log("Service Providers: ", data);
//       setProviders(data[0] || {});
//     } catch (error) {
//       console.error("Error fetching service providers:", error);
//     }
//   };

//   const handleProviders = (provider) => {
//     console.log(provider);
//     switch (provider) {
//       case "Spectrum":
//         // navigate("/partnerRegisterSale", { state: { values: values } });
//         window.open(URL, "_blank", "noopener,noreferrer");
//         break;
//       // You can add more cases here for other providers if needed
//       default:
//         console.log("Provider not handled:", provider);
//     }
//   };

//   // Check if the providers object is empty
//   const isProvidersEmpty =
//     Object.keys(providers).length === 0 || !providers.ZipCode;

//   return (
//     <Box class="m-5">
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Box>
//           <Header title="Select Provider" />
//         </Box>

//         <Box>
//           <Button
//             sx={{
//               backgroundColor: colors.blueAccent[500],
//               color: colors.grey[100],
//               fontSize: "14px",
//               fontWeight: "bold",
//               padding: "10px 20px",
//               mb: "30px",
//             }}
//             component={Link}
//             to={{
//               pathname: "/partnerRegisterSale",
//               state: { values: values }, // Pass the state object here
//             }}
//           >
//             {/* <AddIcon sx={{ mr: "10px" }} /> */}
//             REGISTER SALE
//           </Button>
//         </Box>
//       </Box>
//       <Box
//         mt={"-10px"}
//         ml={"20px"}
//         display="grid"
//         gridTemplateColumns="repeat(1, minmax(0, 1fr))"
//       >
//         <Typography color={colors.primary[800]} variant="h5" fontWeight="800">
//           Current customer:
//         </Typography>
//         <Typography color={colors.primary[600]} variant="p" fontWeight="800">
//           {`${
//             values.firstName +
//             " " +
//             values.lastName +
//             " - " +
//             values.address1 +
//             " - " +
//             values.mobile +
//             " - " +
//             values.email
//           }`}
//         </Typography>
//       </Box>
//       <Box
//         className="m-5"
//         display="grid"
//         gridTemplateColumns="repeat(4, minmax(0, 1fr))"
//       >
//         {isProvidersEmpty ? (
//           <Box sx={{ gridColumn: "span 4", textAlign: "center", mt: 4 }}>
//             <Typography
//               color={colors.greenAccent[500]}
//               variant="h3"
//               fontWeight="bold"
//             >
//               Loading..
//             </Typography>
//           </Box>
//         ) : (
//           <>
//             <Box
//               sx={{ gridColumn: "span 4" }}
//               className="mb-4"
//               display="grid"
//               gridTemplateColumns="repeat(4, minmax(0, auto))"
//               columnGap="20px"
//               rowGap="20px"
//               justifyContent="start"
//             >
//               <Box sx={{ gridColumn: "span 4" }} className="mb-1">
//                 <Typography color={colors.greenAccent[500]} variant="h3">
//                   Internet
//                 </Typography>
//               </Box>
//               {[
//                 "Spectrum",
//                 "Xfinity",
//                 "Optimum",
//                 "Cox",
//                 "Frontier",
//                 "Windstream",
//                 "Earthlink",
//                 "Centurylink",
//                 "Brightspeed",
//                 "Viasat",
//               ].map(
//                 (provider) =>
//                   providers[provider] === "Yes" && (
//                     <Box
//                       onClick={() => handleProviders(provider)}
//                       key={provider}
//                       sx={{
//                         gridColumn: "span 1",
//                         height: "auto",
//                         width: "200px",
//                       }}
//                     >
//                       <img
//                         src={
//                           process.env.PUBLIC_URL +
//                           `assets/Logos/${provider} Button${
//                             provider === "Spectrum" ? "" : " G"
//                           }.png`
//                         }
//                         alt={`${provider} Logo`}
//                       />
//                     </Box>
//                   )
//               )}
//             </Box>
//             <Box
//               sx={{ gridColumn: "span 4" }}
//               className="mb-4"
//               display="grid"
//               gridTemplateColumns="repeat(4, minmax(0, auto))"
//               columnGap="20px"
//               rowGap="20px"
//               justifyContent="start"
//             >
//               <Box sx={{ gridColumn: "span 4" }} className="mt-4 mb-1">
//                 <Typography color={colors.greenAccent[500]} variant="h3">
//                   Television
//                 </Typography>
//               </Box>
//               {["DirectTV"].map(
//                 (provider) =>
//                   providers[provider] === "Yes" && (
//                     <Box
//                       onClick={() => handleProviders(provider)}
//                       key={provider}
//                       sx={{
//                         gridColumn: "span 1",
//                         height: "auto",
//                         width: "200px",
//                       }}
//                     >
//                       <img
//                         src={
//                           process.env.PUBLIC_URL +
//                           `assets/Logos/${provider} Button G.png`
//                         }
//                         alt={`${provider} Logo`}
//                       />
//                     </Box>
//                   )
//               )}
//             </Box>
//           </>
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default ServiceProviders;
