import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";

const PartnersRanking = ({ agent, period }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [agentData, setAgentData] = useState([]);

  useEffect(() => {
    if (agent?.PartnerID) {
      fetchPartnerData();
    }
  }, [agent, period]);

  const fetchPartnerData = async () => {
    const url = "https://us-central1-argon-fx-383518.cloudfunctions.net/SpectPartnerRanking";
    
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ PartnerID: agent.PartnerID, period }),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();

      const formattedData = data.map((item) => ({
        AgentID: item.AgentID,
        FirstName: item.FirstName,
        Internet: item.Internet || 0,
        Video: item.Video || 0,
        Mobile: item.Mobile || 0,
        Voice: item.Voice || 0,
        Chargebacks: item.Chargebacks || 0,
        TotalUnits: item.TotalUnits || 0,
        Canceled: item.Canceled || 0,
        All: item.All || 0,
      }));

      setAgentData(data);
    } catch (error) {
      console.error("Error fetching agent data:", error);
    }
  };

  const columns = [
    { field: "PartnerID", headerName: "Partner", flex: 1 },
    { field: "TotalUnits", headerName: "Total", flex: 1 },
    { field: "Chargebacks", headerName: "CBs", flex: 1 },
    { field: "Canceled", headerName: "Cncld", flex: 1 },
    { field: "Internet", headerName: "Int", flex: 1 },
    { field: "Video", headerName: "Vid", flex: 1 },
    { field: "Mobile", headerName: "Mob", flex: 1 },
    { field: "Voice", headerName: "Voc", flex: 1 },
  ];

  return (
    <Box m="0px 0 0px 0" height="100%" width="auto"
      sx={{
        "& .MuiTablePagination-root, & .MuiDataGrid-root, & .MuiDataGrid-cell, & .MuiDataGrid-footerContainer": {
          color: colors.lightHeaders[100],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
      }}
    >
      <DataGrid
        rows={agentData}
        columns={columns}
        getRowId={(row) => row.PartnerID}
        initialState={{
          sorting: { sortModel: [{ field: "All", sort: "desc" }] },
        }}
      />
    </Box>
  );
};

export default PartnersRanking;
