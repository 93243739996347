// src/scenes/TeamExpViewComps/AgentSales.jsx

import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PieChart from "./PieChart"; // Ensure correct path
import BarChart from "./BarChart"; // Ensure correct path
import { tokens } from "../../theme";
import { useEffect, useState, useRef } from "react";

const AgentSales = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
  
  const PartnerDetails = props.PartnerDetails
  const PartnerObject = props.PartnerObject
  const PartnerID = props.PartnerID
  const Agents = props.Agents
  const providerData = [];
  //   {
  //     id: 1,
  //     provider: "Spectrum",
  //     internet: data.InternetSales,
  //     video: data.VideoSales,
  //     voice: data.VoiceSales,
  //     mobile: 0,
  //     chargebacks: data.Chargebacks,
  //     total: data.Units,
  //   },
  //   {
  //     id: 2,
  //     provider: "DirecTV",
  //     internet: 0,
  //     video: 0,
  //     voice: 0,
  //     mobile: 0,
  //     chargebacks: 0,
  //     total: 0,
  //   },
  //   {
  //     id: 3,
  //     provider: "Viasat",
  //     internet: 0,
  //     video: 0,
  //     voice: 0,
  //     mobile: 0,
  //     chargebacks: 0,
  //     total: 0,
  //   },
  // ];

  const columns = [
    { field: "AgentName", headerName: "Agent", flex: 2 },
    { field: "CurrentMonthSales", headerName: "Units", flex: 1 },
    { field: "Chargebacks", headerName: "Chargebacks", flex: 1 },
    { field: "SalesCanceled", headerName: "Cancelled", flex: 1 },
    { field: "GigSales", headerName: "Gig", flex: 1 },
    { field: "VideoSales", headerName: "Video", flex: 1 },
    { field: "VoiceSales", headerName: "Voice", flex: 1 },
    { field: "VoicePercentage", headerName: "Mobile", flex: 1, renderCell: ({ row: { VoicePercentage } }) => {
      return 0;
    }, },
    { field: "VoiceSales1", headerName: "Dupe #s", flex: 1 , renderCell: ({ row: {} }) => {
      return (0)
    }, },
    { field: "VoiceSales2", headerName: "Init", flex: 1 , renderCell: ({ row: {} }) => {
      return (0)
    }, },
    { field: "Usage", headerName: "Usage", flex: 1, renderCell: ({ row: { CurrentMonthSales, TotalRows } }) => {
      let calc = (CurrentMonthSales / TotalRows) * 100
      // calc = (100 - calc);
      return (0)
    }, },
  ];

  const salesData = {
    totalUnitsSold: {
      label: "Total Units Sold",
      value: PartnerObject.TotalUnits || 0,
      breakdown: {
        internet: PartnerObject.InternetSales || 0,
        video: PartnerObject.VideoSales || 0,
        voice: PartnerObject.VoiceSales || 0,
        mobile: PartnerObject.MobileSales || 0,
      },
    },
    pendingSales: {
      label: "Pending Sales",
      value: PartnerObject.PendingSales || 0,
      breakdown: {
        internet: PartnerObject.InternetPending || 0,
        video: PartnerObject.VideoPending || 0,
        voice: PartnerObject.VoicePending || 0,
        mobile: 0,
      },
    },
    chargebackSales: {
      label: "Chargeback Sales",
      value: PartnerObject.Chargebacks || 0,
      breakdown: {
        internet: PartnerObject.InternetChargebacks || 0,
        video: PartnerObject.VideoChargebacks || 0,
        voice: PartnerObject.VoiceChargebacks || 0,
        mobile: 0, // Assuming no data for mobile yet
      },
    },
    cancelledSales: {
      label: "Canceled Sales",
      value: PartnerObject.TotalCanceled || 0,
      breakdown: {
        internet: PartnerObject.InternetCanceled || 0,
        video: PartnerObject.VideoCanceled || 0,
        voice: PartnerObject.VoiceCanceled || 0,
        mobile: 0,
      },
    },
    ordersCompleted: {
      label: "Orders Completed",
      value: PartnerObject.CompletedEntered || 0,
      breakdown: {
        Entered: PartnerObject.EnteredOrders || 0,
        Complete: PartnerObject.CompletedOrders || 0,
      },
    },
    mobileActivations: {
      label: "Mobile Activations",
      value: PartnerObject.MobileActivations || 0,
      breakdown: {
        Financed: PartnerObject.MobileOnNetwork || 0,
        BYOD: PartnerObject.MobileOffNetwork || 0,
      },
    },
  };

  const dummyData = [
    { id: "Assist", label: "Assist", value: 1 },
    { id: "50/100", label: "50/100", value: 1 },
    { id: "300", label: "300", value: 1 },
    { id: "Ultra", label: "Ultra", value: 1 },
    { id: "Gig", label: "Gig", value: 1 },
  ];

  const InternetData = [
    { id: "Internet Assist", value: PartnerObject.IntAssist },
    { id: "Internet 100", value: PartnerObject.Int100 },
    { id: "Internet 300", value: PartnerObject.Int300 },
    { id: "Internet Ultra", value: PartnerObject.IntUltra },
    { id: "Internet Gig", value: PartnerObject.IntGig },
  ];

  const VideoData = [
    { id: "Mi Plan Latino", value: PartnerObject.VidMPL },
    { id: "Stream Latino", value: PartnerObject.VidSL },
    { id: "Select Signature", value: PartnerObject.VidSS },
    { id: "Choice 15", value: PartnerObject.VidC15 },
    { id: "Stream", value: PartnerObject.VidStream },
    { id: "Select Plus", value: PartnerObject.VidSP },
  ];

  const VoiceData = [
    { id: "Units", value: PartnerObject.Units },
    { id: "Chargebacks", value: PartnerObject.Chargebacks },
    { id: "Canceled", value: PartnerObject.SalesCanceled },
  ];

  const internetData = InternetData || dummyData;
  const videoData = VideoData || dummyData;
  const voiceData = VoiceData || dummyData;

  return (
    <Box
      m="10px 0 40px 0"
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      rowGap="15px"
      columnGap="15px"
      justifyContent={"center"}
    >
{/* Top row with circles */}
<Box
  display="grid"
  gridTemplateColumns="repeat(2, 1fr)"
  rowGap="15px"
  sx={{
    gridColumn: "span 6",
    justifyContent: "space-evenly",
  }}
>
  {Object.keys(salesData).map((key) => (
    <Box
      key={salesData[key].label}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Centering the entire column
      }}
    >
      <Typography
        variant="h6"
        fontWeight="600"
        sx={{ marginBottom: "10px" }}
      >
        {salesData[key].label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center", // Align items vertically center
          justifyContent: "center", // Center the inner box
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start", // Align text to the left
            marginRight: "10px",
          }}
        >
          {Object.keys(salesData[key].breakdown).map((type) => (
            <Typography key={type} variant="body2">
              {`${type.charAt(0).toUpperCase() + type.slice(1)}: ${salesData[key].breakdown[type]}`}
            </Typography>
          ))}
        </Box>
        <Box
        backgroundColor={colors.primary[100]}
          sx={{
            boxShadow: 3,
            padding: "10px",
            // backgroundColor: "#f2f0f0",
            borderRadius: "50%",
            width: 100,
            height: 100,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h3"
            fontWeight="600"
            color={colors.blueAccent[500]}
          >
            {salesData[key].value}
          </Typography>
        </Box>
      </Box>
    </Box>
  ))}
</Box>

      {/* Provider Breakdown */}
      <Box
        backgroundColor={colors.primary[100]}
        sx={{
          gridColumn: "span 6",
          boxShadow: 3,
          padding: "10px",
        }}
      >
        {/* <Typography variant="h5" fontWeight="600" mb="10px">
          Agent Breakdown
        </Typography> */}
        <DataGrid
        getRowId={(row) => row.AgentID}
          rows={Agents}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          disableSelectionOnClick
          // autoHeight
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "transparent",
              borderBottom: "none",
              color: "black",
              fontWeight: "bold", // Makes the column headers bold
              fontSize: "14px", // Adjust font size as needed
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "transparent",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "transparent",
              color: colors.grey[100],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        />
      </Box>

      {/* Services Breakdown */}
      <Box
        backgroundColor={colors.primary[100]}
        sx={{
          gridColumn: "span 6",
          boxShadow: 3,
          padding: "10px",
        }}
      >
        <Typography variant="h5" fontWeight="600" mb="10px">
          Services Breakdown
        </Typography>
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          height="100%"
        >
          <PieChart data={internetData} title="Internet" />
          <PieChart data={videoData} title="Video" />
          <PieChart data={voiceData} title="Mobile" />
        </Box>
      </Box>

      {/* Month over Month Trend */}
      <Box
        backgroundColor={colors.primary[100]}
        sx={{
          gridColumn: "span 6",
          boxShadow: 3,
          padding: "10px",
          height: 300,
        }}
      >
        <Typography variant="h5" fontWeight="600" mb="10px">
          Monthly Sales
        </Typography>
        <BarChart data={data} /> {/* Pass the data prop to BarChart */}
      </Box>
    </Box>
  );
};

export default AgentSales;
