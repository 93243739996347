import { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../../theme";
import { Box, Typography, useTheme } from "@mui/material";

const Circle = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;
  const Data = props.data; // This should come from API response.
  const [internet, setInternet] = useState(0);
  const [video, setVideo] = useState(0);
  const [voice, setVoice] = useState(0);
  const [mobile, setMobile] = useState(0);
  const [totalChargebacks, setTotalChargebacks] = useState(0);
  const [colorScale, setColorScale] = useState("#4cceac");

  useEffect(() => {
    if (
      Data
    ) {
      const chargebackData = Data;

      // Update individual product values
      setInternet(chargebackData.InternetChargebacks || 0);
      setVideo(chargebackData.VideoChargebacks || 0);
      setVoice(chargebackData.VoiceChargebacks || 0);
      setMobile(chargebackData.MobileChargebacks || 0);

      // Update total chargebacks
      const total =
        (chargebackData.InternetChargebacks || 0) +
        (chargebackData.VideoChargebacks || 0) +
        (chargebackData.VoiceChargebacks || 0) +
        (chargebackData.MobileChargebacks || 0);
      setTotalChargebacks(total);

      // Set color scale based on total chargeback percentage
      const totalPercentage = chargebackData.CBPEntryCount
        ? (total / chargebackData.CBPEntryCount) * 100
        : 0;
      setColorScale(totalPercentage > 15 ? "#e22839" : "#4cceac");
    }
  }, [Data, Agent]);

  return (
    <Box
      width="100%"
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      padding="5px"
    >
      <Box gridColumn="span 2">
        <Typography variant="h5" fontWeight="600">
          Chargebacks
        </Typography>
      </Box>
      <Box borderLeft="1px dashed #4cceac" paddingLeft="6px">
        <Box>Internet: {internet}</Box>
        <Box>Video: {video}</Box>
        <Box>Voice: {voice}</Box>
        <Box>Mobile: {mobile}</Box>
      </Box>
      <Box
        borderRadius="50px"
        height="85px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography color={colorScale} variant="h1" fontWeight="600">
          {totalChargebacks}
        </Typography>
      </Box>
    </Box>
  );
};

export default Circle;
