import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        lightHeaders: { 100: "" },
        grey: {
          100: "#f0f0f0",
          200: "#d9d9d9",
          300: "#c2c2c2",
          400: "#ababab",
          500: "#949494",
          600: "#7d7d7d",
          700: "#666666",
          800: "#4f4f4f",
          900: "#383838",
        },
        primary: {
          100: "#d6e4f7", // Light pastel blue for background
          200: "#adc9ef",
          300: "#84afe7",
          400: "#5b94df",
          500: "#3279d7", // Main blue color
          600: "#2861ac",
          700: "#1e497f",
          800: "#143154",
          900: "#0a182a",
        },
        greenAccent: {
          100: "#d0e6f5", // Lighter greyish-blue
          200: "#a1cdec",
          300: "#73b3e2",
          400: "#459ad9",
          500: "#1781cf", // Main greyish-blue accent for positive values
          600: "#1367a6",
          700: "#0f4d7d",
          800: "#0b3355",
          900: "#06192b",
        },
        redAccent: {
          100: "#ffe8e8",
          200: "#ffc0c0",
          300: "#ff9999",
          400: "#ff7272",
          500: "#ff4c4c", // Red for errors
          600: "#e04444",
          700: "#b83838",
          800: "#922c2c",
          900: "#6b2020",
        },
        yellowAccent: {
          100: "#fff6cc", // Softer yellow for readability on blue
          200: "#ffec99",
          300: "#ffe366",
          400: "#ffd933",
          500: "#ffcf00", // Yellow for highlights
          600: "#cc9e00",
          700: "#997c00",
          800: "#665900",
          900: "#332d00",
        },
        blueAccent: {
          100: "#e0f0ff",
          200: "#b3d9ff",
          300: "#80bfff",
          400: "#4da6ff",
          500: "#459ad9", // Main accent blue
          600: "#0070e6",
          700: "#0057b3",
          800: "#004080",
          900: "#00294d",
        },
      }
    : {
        lightHeaders: {
          100: "#ebeef3",
        },
        grey: {
          100: "#ebeef3", // Lighter than original
          200: "#eeeeee",
          300: "#e0e0e0",
          400: "#d6d6d6",
          500: "#cccccc", // Balanced grey
          600: "#b3b3b3",
          700: "#999999",
          800: "#808080",
          900: "#666666", // Darker but still a bit whiter than the original
        },
        primary: {
          100: "#d6e4f7", // Softer blue for background
          200: "#adc9ef",
          300: "#84afe7",
          400: "#5b94df",
          500: "#3279d7", // Main blue color
          600: "#2861ac",
          700: "#1e497f",
          800: "#143154",
          900: "#0a182a",
        },
        greenAccent: {
          100: "#d0e6f5", // Lighter greyish-blue
          200: "#a1cdec",
          300: "#73b3e2",
          400: "#459ad9",
          500: "#1781cf", // Main greyish-blue accent for positive values
          600: "#1367a6",
          700: "#0f4d7d",
          800: "#0b3355",
          900: "#06192b",
        },
        redAccent: {
          100: "#ffe8e8",
          200: "#ffc0c0",
          300: "#ff9999",
          400: "#ff7272",
          500: "#ff4c4c", // Red for warnings or errors
          600: "#e04444",
          700: "#b83838",
          800: "#922c2c",
          900: "#6b2020",
        },
        yellowAccent: {
          100: "#fff6cc", // Softer yellow for readability on white
          200: "#ffec99",
          300: "#ffe366",
          400: "#ffd933",
          500: "#ffcf00", // Yellow highlights
          600: "#cc9e00",
          700: "#997c00",
          800: "#665900",
          900: "#332d00",
        },
        blueAccent: {
          100: "#e0f0ff",
          200: "#b3d9ff",
          300: "#80bfff",
          400: "#4da6ff",
          500: "#459ad9", // Main accent blue
          600: "#0070e6",
          700: "#0057b3",
          800: "#004080",
          900: "#00294d",
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
          }
        : {
            // LIGHT THEME HERE VVVVVVVVVVV
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#eaedf3eb",
            },
            warning: {
              main: colors.redAccent[500],
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
